import Layout from "../components/layout";
import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { paraisoDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const Post = ({data}) => {
  return (
    <Layout>
      <h1>{data.contentfulPost.title}</h1>
      <p className="text-muted"><small>{(new Date(data.contentfulPost.createdAt)).toLocaleDateString()}</small></p>
      {documentToReactComponents(data.contentfulPost.content.json, {
        renderMark: {
          [MARKS.CODE]: (text) => {
            const language = text.split('\n')[0];
            return <SyntaxHighlighter language={language} style={paraisoDark}>{text.substr(language.length + 1)}</SyntaxHighlighter>
          }
        },
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
            <img alt={node.data.target.fields.title["en-US"]} className="img-fluid" src={node.data.target.fields.file["en-US"].url} />
          ),
          // To fix pre inside paragraphs
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <div>{children}</div>
          )
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query postQuery($slug: String!) {
    contentfulPost(slug: {eq: $slug}) {
      title
      createdAt
      content {
        json
      }
    }
  }
`;

export default Post;
